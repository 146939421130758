import React from "react"
import { graphql } from "gatsby"
import Layout from "../../components/layout"
import Testimonials from "../../components/testimonials"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faFacebookF,
  faTwitter,
  faInstagram,
  faLinkedinIn,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons"
import { faArrowRight } from "@fortawesome/free-solid-svg-icons"

export const data = graphql`
  query {
    allBlogDataset(filter: { Category: { in: "News" } }) {
      edges {
        node {
          Title
          SlugURL
          Category
          Excerpt
          FeaturedImageURL
        }
      }
    }
  }
`

export const seo = {
  title: "Newsroom | Crampete",
  description:
    "In-depth news articles on up-skilling, placement preparation, emerging tech skills like data science, digital marketing, full stack web development and more. Read on...",
  keywords: "Full Stack development, Data Science, Digital Marketing",
  image: "",
  url: "https://www.crampete.com/news",
  author: "Crampete",
}

const News = ({ data }) => {
  const news = data.allBlogDataset.edges

  return (
    <Layout
      title={seo.title}
      description={seo.description}
      image={seo.image}
      keywords={seo.keywords}
      url={seo.url}
      author={seo.author}
    >
      <div className="container" style={{ fontFamily: "Lato" }}>
        <div>
          <div className="d-flex">
            <h1
              className="font-weight-bold"
              style={{ fontSize: "42px", color: "#253B50" }}
            >
              newsroom_
            </h1>
            <ul className="list-unstyled list-inline ml-5 mt-4">
              <li className="list-inline-item  ">
                <a
                  href="https://www.facebook.com/crampete/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-dark"
                >
                  <FontAwesomeIcon icon={faFacebookF} />
                </a>
              </li>
              <li className="list-inline-item ml-1">
                <a
                  href="https://twitter.com/trycrampete"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-dark"
                >
                  <FontAwesomeIcon icon={faTwitter} />
                </a>
              </li>
              <li className="list-inline-item ml-1">
                <a
                  href="https://www.instagram.com/trycrampete/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-dark"
                >
                  <FontAwesomeIcon icon={faInstagram} />
                </a>
              </li>
              <li className="list-inline-item ml-1">
                <a
                  href="https://www.linkedin.com/company/14505664/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-dark"
                >
                  <FontAwesomeIcon icon={faLinkedinIn} />
                </a>
              </li>
              <li className="list-inline-item ml-1">
                <a
                  href="https://www.youtube.com/channel/UCs3sKIFU4o4AblGJHtknHIg"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-dark"
                >
                  <FontAwesomeIcon icon={faYoutube} />
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="d-flex mt-3">
          <a
            href="/newsroom/events/"
            className="text-decoration-none"
          >
            <div className="row">
              <div className="col-md-7 pl-md-3 " style={{ padding: "0px" }}>
                <div>
                  <img
                    src="https://crampete-staticfiles.s3.ap-south-1.amazonaws.com/newsroom/data-science-boot-camp-banner.png
                    "
                    style={{ width: "100%", height: "360px" }}
                  />
                </div>
              </div>
              <div className="col-md-5 border border-left-0">
                <div className="ml-md-3">
                  <div>
                    <p
                      className="mt-4"
                      style={{ fontSize: "13px", color: "#5C6B79" }}
                    >
                      LATEST HAPPENINGS
                    </p>
                    <h2
                      className="font-weight-bold"
                      style={{ fontSize: "32px", color: "#253B50" }}
                    >
                      Data Science Basics and Opportunities
                    </h2>
                    <p
                      style={{ fontSize: "17px", color: "#253B50" }}
                      className="pb-3"
                    >
                      <span className="font-weight-bold">Crampete</span>, a
                      pioneer in the EdTech space who has been successfully
                      equipping students with in demand technology training for
                      the last four years,{" "}
                      <span className="font-weight-bold">Crampete</span> and{" "}
                      <span className="font-weight-bold">
                        DMI College of Engineering
                      </span>{" "}
                      have joined hands in imparting knowledge on Data Science
                      to the students.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </a>
        </div>
        <div className="mt-5 ">
          <div>
            <h4 className="font-weight-bold">TOP STORIES</h4>
          </div>

          <div className="container mt-5">
            <div className="row">
              <div className="col-sm ">
                <a
                  href="/blogs/full-stack-developer-course-syllabus/"
                  className="text-decoration-none"
                >
                  <div className="row pb-3 pb-md-0">
                    <div className="col-4 p-0">
                      <img
                        src="
                  https://crampete-staticfiles.s3.ap-south-1.amazonaws.com/newsroom/payroll-process.png
"
                        className=""
                      />
                    </div>
                    <div className="col-8 p-0 border-bottom ">
                      <h5
                        style={{
                          fontSize: "13px",
                          color: "#5C6B79",
                          marginBottom: "5px",
                        }}
                      >
                        Full stack Developer course Syllabus
                      </h5>
                      <p
                        className="font-weight-bold"
                        style={{
                          fontSize: "17px",
                          color: "#253B50",
                          margin: "0px",
                          lineHeight: "24px",
                        }}
                      >
                        Full-Stack Development Course Syllabus (2022 updated)
                      </p>
                    </div>
                  </div>
                </a>
              </div>
              <div className="col-sm ml-md-3">
                <a
                  href="/blogs/front-end-developer-salary-in-india/"
                  className="text-decoration-none"
                >
                  <div className="row pb-3 pb-md-0">
                    <div className="col-4 p-0">
                      <img
                        src="https://crampete-staticfiles.s3.ap-south-1.amazonaws.com/newsroom/practical-learning.png"
                        className=""
                      />
                    </div>
                    <div className="col-8 p-0 border-bottom ">
                      <h5
                        style={{
                          fontSize: "13px",
                          color: "#5C6B79",
                          marginBottom: "5px",
                        }}
                      >
                        Front End Developer Salary in India
                      </h5>
                      <p
                        className="font-weight-bold"
                        style={{
                          fontSize: "17px",
                          color: "#253B50",
                          margin: "0px",
                          lineHeight: "24px",
                        }}
                      >
                        Wondering what is the range of front end developer
                        salary oin India...
                      </p>
                    </div>
                  </div>
                </a>
              </div>
              <div className="col-sm ml-md-3">
                <a
                  href="/blogs/full-stack-vs-mean-stack/"
                  className="text-decoration-none"
                >
                  <div className="row pb-3 pb-md-0">
                    <div className="col-4 p-0">
                      <img
                        src="https://crampete-staticfiles.s3.ap-south-1.amazonaws.com/newsroom/coding.png"
                        className=""
                      />
                    </div>
                    <div className="col-8 p-0 border-bottom ">
                      <h5
                        style={{
                          fontSize: "13px",
                          color: "#5C6B79",
                          marginBottom: "5px",
                        }}
                      >
                        Full Stack vs Mean Stack
                      </h5>
                      <p
                        className="font-weight-bold"
                        style={{
                          fontSize: "17px",
                          color: "#253B50",
                          margin: "0px",
                          lineHeight: "24px",
                        }}
                      >
                        Know the difference between Full Stack vs Mean Stack
                      </p>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-5 pt-md-5">
          <div className="text-center">
            <h3 className="font-weight-bold">A community in the making!</h3>
            <p>
              Here's what our students have to say about being a part of the
              Crampete family and learning skills that empower them! A community
              in the making- sharing skills, experience and knowledge!
            </p>
          </div>
          <div className="py-5">
            <Testimonials />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default News
